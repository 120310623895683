<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10 1.66602C10.5373 1.66602 10.9729 2.10159 10.9729 2.63889L10.9729 11.3616L14.0571 8.27739C14.437 7.89746 15.053 7.89746 15.433 8.27739C15.8129 8.65732 15.8129 9.27332 15.433 9.65325L10.6881 14.3981C10.5122 14.574 10.2686 14.683 10 14.683C9.7253 14.683 9.47697 14.569 9.30024 14.386L4.56748 9.65325C4.18754 9.27332 4.18754 8.65732 4.56748 8.27739C4.94741 7.89746 5.5634 7.89746 5.94333 8.27739L9.02713 11.3612L9.02713 2.63889C9.02713 2.10159 9.4627 1.66602 10 1.66602ZM2.48655 10.8513C3.02386 10.8513 3.45943 11.2869 3.45943 11.8242V13.8554C3.45943 14.4848 3.46019 14.8983 3.48604 15.2147C3.51097 15.5199 3.55431 15.648 3.59212 15.7222C3.70883 15.9513 3.89507 16.1375 4.12414 16.2542C4.19834 16.292 4.32649 16.3354 4.63164 16.3603C4.94805 16.3862 5.36152 16.3869 5.99099 16.3869H14.0074C14.6368 16.3869 15.0503 16.3862 15.3667 16.3603C15.6719 16.3354 15.8 16.292 15.8742 16.2542C16.1033 16.1375 16.2895 15.9513 16.4062 15.7222C16.444 15.648 16.4874 15.5199 16.5123 15.2147C16.5382 14.8983 16.5389 14.4848 16.5389 13.8554V11.8242C16.5389 11.2869 16.9745 10.8513 17.5118 10.8513C18.0491 10.8513 18.4847 11.2869 18.4847 11.8242V13.8945C18.4847 14.4748 18.4847 14.9681 18.4516 15.3732C18.4168 15.7986 18.3408 16.2113 18.1399 16.6056C17.8367 17.2008 17.3528 17.6847 16.7576 17.9879C16.3633 18.1888 15.9506 18.2649 15.5252 18.2996C15.1201 18.3327 14.6268 18.3327 14.0465 18.3327H5.95181C5.37158 18.3327 4.87825 18.3327 4.4732 18.2996C4.04776 18.2649 3.63503 18.1888 3.24078 17.9879C2.6456 17.6847 2.1617 17.2008 1.85844 16.6056C1.65756 16.2113 1.5815 15.7986 1.54674 15.3732C1.51365 14.9681 1.51366 14.4748 1.51367 13.8946L1.51367 11.8242C1.51367 11.2869 1.94925 10.8513 2.48655 10.8513Z"
      fill="white"
      fill-opacity="0.75"
    />
  </svg>
</template>
