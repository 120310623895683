<template>
  <div :class="$style.popup">
    <slot />
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(defineProps<{ maxWidth?: number }>(), {
  maxWidth: 470,
});
const maxWidthPx = computed(() => `${props.maxWidth}px`);
</script>

<style lang="scss" module>
.popup {
  position: relative;
  max-width: v-bind(maxWidthPx);
  width: 100%;
  padding: var(--spacing-4xl);
  border-radius: var(--radius-7xl);
  background: var(--general-underlay-3-lvl);
  @media screen and (max-width: $mobile) {
    padding: var(--spacing-4xl) var(--spacing-lg);
    border-radius: var(--radius-7xl) var(--radius-7xl) 0 0;
  }
}
</style>
